<template>
  <!-- 申请开票记录 -->
  <div ref="invoiceRecord" class="invoiceRecord">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="queryForm"
        label-position="left"
        label-width="85px"
        class="demo-form-inline"
      >
        <el-form-item label="申请时间：" class="code-time-range">
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单编号：">
          <el-input
            v-model="queryForm.orderId"
            clearable
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="购买方名称：">
          <el-input
            v-model="queryForm.buyerName"
            clearable
            placeholder="请输入购买方名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button type="primary" size="small" @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="TableHeight"
    >
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  queryPassengerApplyInvoiceRecordPage,
  exportPassengerApplyInvoiceRecord,
  getCompanyTree,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      tableData: [],
      TableHeight: 0,
      TableHeight2: 0,
      titleName: [
        {
          title: "申请时间",
          props: "applyTime",
        },
        {
          title: "购买方纳税人识别号",
          props: "buyerTaxNumber",
        },
        {
          title: "购买方名称",
          props: "buyerName",
        },
        {
          title: "购方客户邮箱",
          props: "buyerEmail",
        },
        {
          title: "联系电话",
          props: "tel",
        },
        {
          title: "金额",
          props: "money",
        },
        {
          title: "备注",
          props: "remark",
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "线路",
          props: "lineName",
        },
        {
          title: "订单编号",
          props: "orderId",
        },
        {
          title: "票号",
          props: "childOrderId",
        },
        {
          title: "订单类型",
          props: "orderType",
          SpecialJudgment: (res) => {
            return res == 1
              ? "定制客运"
              : res == 2
              ? "汽车订票"
              : res == 6
              ? "定制班线"
              : res == 100
              ? "购票服务费"
              : "";
          },
        },
      ],
      orderType: [],
      value1: null,
      queryForm: {
        applyTimeStart: "",
        applyTimeEnd: "",
        companyIds: [],
        buyerName: "",
        orderId: "",
        pageSize: 10,
        currentPage: 1,
      },
      total: 0,
      companyList: [],
      value: null,
      valuename: null,
      exportForm: {},
    };
  },
  methods: {
    queryFun() {
      if (!this.value1) {
        this.queryForm.applyTimeStart = "";
        this.queryForm.applyTimeEnd = "";
      } else {
        this.queryForm.applyTimeStart = this.value1[0];
        this.queryForm.applyTimeEnd = this.value1[1];
      }
      this.exportForm = this.deepClone(this.queryForm);
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.invoiceRecord.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
      this.TableHeight2 = wholeHeight - 240;
    },
    exportFun() {
      this.exportForm = this.deepClone(this.queryForm);
      exportPassengerApplyInvoiceRecord(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "申请开票记录 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      queryPassengerApplyInvoiceRecordPage(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getGroupId(v) {
      if (v == null) {
        this.queryForm.companyIds = [];
      } else {
        this.queryForm.companyIds = [v];
      }
    },
  },
  mounted() {
    // 时间计算，默认一个月
    let day = new Date();
    let text =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.value1 = [lastTime(new Date()) + " 00:00:00", text];
    this.exportForm = this.deepClone(this.queryForm);
    this.queryFun();
    this.getCompanyList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
};
</script>

<style scoped lang="scss">
.invoiceRecord {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.Table {
  margin-top: 16px;
}
/deep/.el-form {
  z-index: 999;
}
/deep/.download {
  text-decoration: none;
}
.code-time-range {
  .el-date-editor {
    width: 14vw !important;
    ::v-deep .el-range-separator {
      line-height: 24px;
    }
  }
}
</style>
